import styled from 'styled-components';

const NavigationBuildingStyle = styled.div`
	--padTB: var(--sp3x);
	--padLR: var(--sp3x);
	--distanceItem: var(--sp2x);
	--borderRadius: var(--sp2x);
	--distanceArrowTitle: var(--sp1-5x);
	--distanceMenuTitle: var(--sp2x);

	position: relative;

	.wrapper-title {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		gap: var(--distanceArrowTitle);
	}

	.navigation-inner-building {
		position: absolute;
		transform: translateX(calc(var(--padLR) * -1));
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: var(--distanceItem);
		padding: var(--padTB) var(--padLR);
		border-radius: var(--borderRadius);
		background-color: var(--white);
		cursor: default;
		clip-path: inset(0 0 100% 0);
		transition: all var(--trTime) ease-out;
		margin-top: var(--distanceMenuTitle);

		&.active {
			clip-path: inset(0 0 0 0);
		}
	}

	.wrapper-navigation-building {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	.building-arrow {
		transform: rotate(0);
		transition: transform var(--trTime) ease-out;

		&.activeIcon {
			transform: rotate(180deg);
		}
	}

	.wrapper-item {
		text-align: center;
		transition: color var(--triTime) ease-out;
		width: fit-content;

		&.active-link {
			pointer-events: none;
			color: var(--green1000);
		}
	}

	.item {
		width: 100%;
		transition: color var(--trTime) ease-out;
	}

	&.is-burger-menu {
		position: relative;

		.wrapper-title {
			justify-content: flex-start;
		}
		.building-arrow {
			display: none;
		}

		.wrapper-navigation-building {
			position: relative;
		}

		.navigation-inner-building {
			transform: translate(0, 0);
			position: relative;
			margin-top: 0;
			padding: 0;
			padding-top: var(--distanceItem);
		}

		.buildings-title {
			font-size: var(--sp3x);
		}
	}

	@media (hover: hover) {
		.item {
			&:hover {
				color: var(--green1000);
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--padTB: var(--sp2-5x);
		--padLR: var(--sp2-5x);
		--distanceItem: var(--sp2x);
		--borderRadius: var(--sp2x);
		--distanceArrowTitle: var(--sp1-5x);
		--distanceMenuTitle: var(--sp2x);
	}
	/* //! 1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--padTB: var(--sp2x);
		--padLR: var(--sp2x);
		--distanceItem: var(--sp2x);
		--borderRadius: var(--sp2x);
		--distanceArrowTitle: var(--sp1x);
		--distanceMenuTitle: var(--sp3x);
	}
	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--padTB: var(--sp2x);
		--padLR: var(--sp2x);
		--distanceItem: var(--sp2x);
		--borderRadius: var(--sp2x);
		--distanceArrowTitle: var(--sp1x);
		--distanceMenuTitle: var(--sp2x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--padTB: var(--sp2x);
		--padLR: var(--sp2x);
		--distanceItem: var(--sp2x);
		--borderRadius: var(--sp2x);
		--distanceArrowTitle: var(--sp1x);
		--distanceMenuTitle: var(--sp2x);
	}
	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--padTB: var(--sp2x);
		--padLR: var(--sp2x);
		--distanceItem: var(--sp2x);
		--borderRadius: var(--sp2x);
		--distanceArrowTitle: var(--sp1x);
		--distanceMenuTitle: var(--sp2x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
	}
	/* //! 414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
	}
`;
export default NavigationBuildingStyle;
