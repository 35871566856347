import styled from 'styled-components';

const HeaderStyle = styled.header`
	--headerPadding: var(--sp4x) var(--sp12x);
	--distanceButtonLanguageGAP: var(--sp4x);
	--distanceMenuListGAP: var(--sp4x);

	height: var(--headerFixedHeight);
	position: fixed;
	width: 100%;
	top: -100%;
	transition: top var(--trTime);
	z-index: 7;
	opacity: 0;

	&.visible {
		top: 0;
	}

	.inner-header-wrapper {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: var(--headerPadding);
		width: 100%;
	}

	/* & + main {
		${({ $header_height }) => ($header_height ? `margin-top: ${$header_height}px` : ``)}
	} */

	.header-logo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.button-language-wrapper,
	.navigation-wrapper,
	.wrapper-logo-menu {
		display: flex;
		align-items: center;
	}

	.button-language-wrapper {
		gap: var(--distanceButtonLanguageGAP);
	}

	.navigation-wrapper {
		gap: var(--distanceMenuListGAP);
	}

	.menu-link {
		&.active-link {
			color: var(--green1000);
			pointer-events: none;
		}
	}

	.phone-button {
		white-space: nowrap;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--headerPadding: var(--sp2-5x) var(--sp10x);
		--distanceButtonLanguageGAP: var(--sp3x);
		--distanceMenuListGAP: var(--sp7x);
	}

	/* //! 1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--headerPadding: var(--sp2-5x) var(--sp8x);
		--distanceButtonLanguageGAP: var(--sp2x);
		--distanceMenuListGAP: var(--sp2x);
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerPadding: var(--sp2x) var(--sp7x);
		--distanceButtonLanguageGAP: var(--sp2x);
		--distanceMenuListGAP: var(--sp2x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerPadding: var(--sp2x) var(--sp6x);
		--distanceButtonLanguageGAP: var(--sp2x);
		--distanceMenuListGAP: var(--sp2x);
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--headerPadding: var(--sp2x) var(--sp6x);
		--distanceButtonLanguageGAP: var(--sp2x);
		--distanceMenuListGAP: var(--sp2x);

		.header-logo {
			position: relative;
			left: 0;
			top: 0;
			transform: translate(0, 0);
			margin-right: var(--sp4x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--headerPadding: var(--sp2x) var(--sp4x);
		--distanceButtonLanguageGAP: var(--sp2x);
		--distanceMenuListGAP: var(--sp2x);

		.header-logo {
			position: relative;
			left: 0;
			top: 0;
			transform: translate(0, 0);
			margin-right: var(--sp3x);
		}

		.navigation-wrapper {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--headerPadding: var(--sp2x) var(--sp3x);
		--distanceButtonLanguageGAP: var(--sp1-5x);
		--distanceMenuListGAP: var(--sp3x);

		.header-logo {
			position: relative;
			left: 0;
			top: 0;
			transform: translate(0, 0);
			margin-right: var(--sp1x);
		}

		.request-button {
			display: none;
		}

		.navigation-wrapper {
			display: none;
		}
	}
`;

export default HeaderStyle;
