import { memo as Memo } from 'react';

//* Component's
import CustomLink from '@/components/common/CustomLink';
import Image from '@/components/common/Image';

//* Style
import LogoStyle from './style';

const Logo = Memo(({ className, type, white, onClick }) => {
	return (
		<LogoStyle
			className={className || ''}
			type={type}
			onClick={onClick}>
			<CustomLink
				// content={
				// 	<Image
				// 		src={white ? `/images/logo-white.svg` : `/images/logo.svg`}
				// 		alt={`Logo`}
				// 	/>
				// }

				content={
					white ? (
						<svg
							className={'white-logo'}
							width='282'
							height='71.596'
							viewBox='0 0 282 71.596'>
							<defs>
								<clipPath id='clipPath'>
									<rect
										id='Rectangle_36036'
										data-name='Rectangle 36036'
										width='282.001'
										height='71.596'
										transform='translate(-0.673 -0.173)'
									/>
								</clipPath>
							</defs>
							<g
								id='Sunday_towers_logo'
								transform='translate(0.674 0.172)'
								clipPath='url(#clipPath)'>
								<path
									id='Path_55958'
									data-name='Path 55958'
									d='M73.391,21.47h8.264A4.966,4.966,0,0,0,82.785,23.3a6.985,6.985,0,0,0,5.132,1.827,6.59,6.59,0,0,0,3.393-.7A2.159,2.159,0,0,0,92.441,22.6a1.47,1.47,0,0,0-.261-.87,3.325,3.325,0,0,0-.783-.783,6.485,6.485,0,0,0-1.044-.609,9.322,9.322,0,0,0-1.479-.522c-.609-.174-1.218-.348-1.653-.435s-1.044-.261-1.914-.435c-.783-.174-1.479-.348-1.914-.435a18.721,18.721,0,0,1-6.611-3.131,6.847,6.847,0,0,1-2.436-5.741A8.645,8.645,0,0,1,77.74,2.856C80,1.029,83.047.072,86.961.072c4.958,0,8.612,1.566,11.048,4.784a11.465,11.465,0,0,1,2.175,4.784h-8.09a3.8,3.8,0,0,0-.87-1.3,5.771,5.771,0,0,0-4.089-1.3A6.049,6.049,0,0,0,84,7.64a1.757,1.757,0,0,0-.957,1.566,2.176,2.176,0,0,0,1.479,1.914,24.392,24.392,0,0,0,4.262,1.392,20.7,20.7,0,0,1,2.349.609c.435.087,1.218.348,2.262.7a14.188,14.188,0,0,1,2.436.957,20.7,20.7,0,0,1,2,1.218,7.831,7.831,0,0,1,1.74,1.566,11.574,11.574,0,0,1,1.044,2.088,6.592,6.592,0,0,1,.435,2.61A8.89,8.89,0,0,1,97.661,29.3c-2.262,1.914-5.48,2.871-9.569,2.871-5.132,0-9.134-1.74-11.918-5.306A15.166,15.166,0,0,1,73.391,21.47Z'
									transform='translate(15.781 -0.197)'
								/>
								<path
									id='Path_55959'
									data-name='Path 55959'
									d='M98.453,18.982V.715h8V18.721a6.034,6.034,0,0,0,1.478,4.262,5.441,5.441,0,0,0,4.089,1.566,5.309,5.309,0,0,0,4.088-1.566,5.671,5.671,0,0,0,1.478-4.262V.715h8V18.982a12.646,12.646,0,0,1-3.653,9.481c-2.436,2.349-5.829,3.566-10,3.566a14.071,14.071,0,0,1-10-3.566A13.386,13.386,0,0,1,98.453,18.982Z'
									transform='translate(21.165 -0.059)'
								/>
								<path
									id='Path_55960'
									data-name='Path 55960'
									d='M124.516,31.073V.715h9.742l10.613,19.137h.435l-.609-3.914V.715h8V31.073h-9.743l-10.613-19.05h-.435l.609,3.914V31.073Z'
									transform='translate(26.764 -0.059)'
								/>
								<path
									id='Path_55961'
									data-name='Path 55961'
									d='M151.523,31.073V.715h11.744c5.045,0,9.047,1.479,12.179,4.349a14.105,14.105,0,0,1,4.7,10.786,13.976,13.976,0,0,1-4.7,10.786,17.394,17.394,0,0,1-12.179,4.349H151.523Zm8-7.394h3.654a8.786,8.786,0,0,0,6.263-2.088,7.473,7.473,0,0,0,2.174-5.741,7.473,7.473,0,0,0-2.174-5.741,8.786,8.786,0,0,0-6.263-2.088h-3.654Z'
									transform='translate(32.567 -0.059)'
								/>
								<path
									id='Path_55962'
									data-name='Path 55962'
									d='M174.727,31.073,182.99.715h14.788l8.265,30.358h-8.265L196.3,24.984H184.556l-1.478,6.089ZM186.21,18.112h8.263l-2.609-10h-3.044Z'
									transform='translate(37.552 -0.059)'
								/>
								<path
									id='Path_55963'
									data-name='Path 55963'
									d='M197.414.715h8.438l6.524,11.743h.609L219.509.715h8.438L216.726,20.286V31.073h-8.091V20.286Z'
									transform='translate(42.426 -0.059)'
								/>
								<path
									id='Path_55964'
									data-name='Path 55964'
									d='M73.391,40.6V33.293h27.141V40.6H90.963V63.651h-8V40.6Z'
									transform='translate(15.781 6.94)'
								/>
								<path
									id='Path_55965'
									data-name='Path 55965'
									d='M101.237,59.964a15.757,15.757,0,0,1,0-22.616,17.913,17.913,0,0,1,24.184,0,15.756,15.756,0,0,1,0,22.616,17.913,17.913,0,0,1-24.184,0Zm6-17.658a8.463,8.463,0,0,0-2.348,6.263,8.464,8.464,0,0,0,2.348,6.263,9.069,9.069,0,0,0,12.179,0,8.464,8.464,0,0,0,2.348-6.263,8.464,8.464,0,0,0-2.348-6.263,8.194,8.194,0,0,0-6.089-2.349A7.7,7.7,0,0,0,107.24,42.306Z'
									transform='translate(20.735 6.802)'
								/>
								<path
									id='Path_55966'
									data-name='Path 55966'
									d='M125.242,33.225h8l3.914,21.311h.435l4.089-21.311h9.656l4.088,21.311h.435l3.828-21.311h8.09l-6.35,30.445H150.643l-3.914-20.441h-.435L142.38,63.669H131.506Z'
									transform='translate(26.92 6.926)'
								/>
								<path
									id='Path_55967'
									data-name='Path 55967'
									d='M162.18,63.669V33.225h23.486v7.394h-15.4v4.61h12.352v6.524H170.27v4.523h15.4v7.394Z'
									transform='translate(34.856 6.926)'
								/>
								<path
									id='Path_55968'
									data-name='Path 55968'
									d='M184.383,63.669V33.225h15.223a12.693,12.693,0,0,1,8.786,3.045,9.671,9.671,0,0,1,3.393,7.568c0,3.914-2,6.959-6.09,9.133l6.264,10.612h-8.7l-5.393-9.133h-5.394v9.133h-8.09Zm8-16.527h6.959a4.472,4.472,0,0,0,2.958-.957,2.828,2.828,0,0,0,0-4.523,4.641,4.641,0,0,0-2.958-.957h-6.959Z'
									transform='translate(39.626 6.926)'
								/>
								<path
									id='Path_55969'
									data-name='Path 55969'
									d='M208.3,53.976h8.263a4.979,4.979,0,0,0,1.131,1.827,6.987,6.987,0,0,0,5.133,1.827,6.592,6.592,0,0,0,3.393-.7,2.16,2.16,0,0,0,1.131-1.827,1.471,1.471,0,0,0-.261-.87,3.306,3.306,0,0,0-.784-.783,6.485,6.485,0,0,0-1.044-.609,9.315,9.315,0,0,0-1.478-.522c-.61-.174-1.219-.348-1.653-.435s-1.044-.261-1.913-.435c-.784-.174-1.479-.348-1.913-.435a18.726,18.726,0,0,1-6.612-3.131,6.848,6.848,0,0,1-2.434-5.741,8.642,8.642,0,0,1,3.392-6.785c2.262-1.827,5.306-2.784,9.221-2.784,4.959,0,8.612,1.566,11.048,4.784a11.448,11.448,0,0,1,2.175,4.784h-8.263a3.794,3.794,0,0,0-.87-1.3,5.772,5.772,0,0,0-4.089-1.3,6.045,6.045,0,0,0-3.131.609,1.757,1.757,0,0,0-.957,1.566,2.177,2.177,0,0,0,1.478,1.914,24.407,24.407,0,0,0,4.263,1.392,20.687,20.687,0,0,1,2.348.609c.435.087,1.218.348,2.262.7a14.184,14.184,0,0,1,2.436.957,20.723,20.723,0,0,1,2,1.218,7.846,7.846,0,0,1,1.74,1.566,11.576,11.576,0,0,1,1.044,2.088,8.193,8.193,0,0,1,.435,2.609,8.892,8.892,0,0,1-3.392,7.046c-2.262,1.914-5.48,2.87-9.569,2.87-5.133,0-9.134-1.74-11.918-5.306A15.57,15.57,0,0,1,208.3,53.976Z'
									transform='translate(44.764 6.787)'
								/>
								<path
									id='Path_55970'
									data-name='Path 55970'
									d='M0,71.675H66.895V0H0ZM63.763,3.131V42.623A49.567,49.567,0,0,0,33.4,32.358,50.118,50.118,0,0,0,3.045,42.623V3.131ZM3.132,68.544V46.624A46.672,46.672,0,0,1,33.491,35.49,47.141,47.141,0,0,1,63.85,46.624v21.92Z'
									transform='translate(0.014 -0.212)'
								/>
							</g>
						</svg>
					) : (
						<svg
							width='282.001'
							height='71.596'
							viewBox='0 0 282.001 71.596'>
							<defs>
								<clipPath id='clipPath'>
									<rect
										id='Rectangle_2'
										data-name='Rectangle 2'
										width='282.001'
										height='71.596'
										transform='translate(-0.673 -0.173)'
									/>
								</clipPath>
							</defs>
							<g
								id='Sunday_towers_logo'
								transform='translate(0.673 0.173)'
								clipPath='url(#clipPath)'>
								<path
									id='Path_1'
									data-name='Path 1'
									d='M73.391,21.47h8.264A4.966,4.966,0,0,0,82.785,23.3a6.985,6.985,0,0,0,5.132,1.827,6.59,6.59,0,0,0,3.393-.7A2.159,2.159,0,0,0,92.441,22.6a1.47,1.47,0,0,0-.261-.87,3.325,3.325,0,0,0-.783-.783,6.485,6.485,0,0,0-1.044-.609,9.322,9.322,0,0,0-1.479-.522c-.609-.174-1.218-.348-1.653-.435s-1.044-.261-1.914-.435c-.783-.174-1.479-.348-1.914-.435a18.721,18.721,0,0,1-6.611-3.131,6.847,6.847,0,0,1-2.436-5.741A8.645,8.645,0,0,1,77.74,2.856C80,1.029,83.047.072,86.961.072c4.958,0,8.612,1.566,11.048,4.784a11.465,11.465,0,0,1,2.175,4.784h-8.09a3.8,3.8,0,0,0-.87-1.3,5.771,5.771,0,0,0-4.089-1.3A6.049,6.049,0,0,0,84,7.64a1.757,1.757,0,0,0-.957,1.566,2.176,2.176,0,0,0,1.479,1.914,24.392,24.392,0,0,0,4.262,1.392,20.7,20.7,0,0,1,2.349.609c.435.087,1.218.348,2.262.7a14.188,14.188,0,0,1,2.436.957,20.7,20.7,0,0,1,2,1.218,7.831,7.831,0,0,1,1.74,1.566,11.574,11.574,0,0,1,1.044,2.088,6.592,6.592,0,0,1,.435,2.61A8.89,8.89,0,0,1,97.661,29.3c-2.262,1.914-5.48,2.871-9.569,2.871-5.132,0-9.134-1.74-11.918-5.306A15.166,15.166,0,0,1,73.391,21.47Z'
									transform='translate(15.781 -0.197)'
								/>
								<path
									id='Path_2'
									data-name='Path 2'
									d='M98.453,18.982V.715h8V18.721a6.034,6.034,0,0,0,1.478,4.262,5.441,5.441,0,0,0,4.089,1.566,5.309,5.309,0,0,0,4.088-1.566,5.671,5.671,0,0,0,1.478-4.262V.715h8V18.982a12.646,12.646,0,0,1-3.653,9.481c-2.436,2.349-5.829,3.566-10,3.566a14.071,14.071,0,0,1-10-3.566A13.386,13.386,0,0,1,98.453,18.982Z'
									transform='translate(21.165 -0.059)'
								/>
								<path
									id='Path_3'
									data-name='Path 3'
									d='M124.516,31.073V.715h9.742l10.613,19.137h.435l-.609-3.914V.715h8V31.073h-9.743l-10.613-19.05h-.435l.609,3.914V31.073Z'
									transform='translate(26.764 -0.059)'
								/>
								<path
									id='Path_4'
									data-name='Path 4'
									d='M151.523,31.073V.715h11.744c5.045,0,9.047,1.479,12.179,4.349a14.105,14.105,0,0,1,4.7,10.786,13.976,13.976,0,0,1-4.7,10.786,17.394,17.394,0,0,1-12.179,4.349H151.523Zm8-7.394h3.654a8.786,8.786,0,0,0,6.263-2.088,7.473,7.473,0,0,0,2.174-5.741,7.473,7.473,0,0,0-2.174-5.741,8.786,8.786,0,0,0-6.263-2.088h-3.654Z'
									transform='translate(32.567 -0.059)'
								/>
								<path
									id='Path_5'
									data-name='Path 5'
									d='M174.727,31.073,182.99.715h14.788l8.265,30.358h-8.265L196.3,24.984H184.556l-1.478,6.089ZM186.21,18.112h8.263l-2.609-10h-3.044Z'
									transform='translate(37.552 -0.059)'
								/>
								<path
									id='Path_6'
									data-name='Path 6'
									d='M197.414.715h8.438l6.524,11.743h.609L219.509.715h8.438L216.726,20.286V31.073h-8.091V20.286Z'
									transform='translate(42.426 -0.059)'
								/>
								<path
									id='Path_7'
									data-name='Path 7'
									d='M73.391,40.6V33.293h27.141V40.6H90.963V63.651h-8V40.6Z'
									transform='translate(15.781 6.94)'
								/>
								<path
									id='Path_8'
									data-name='Path 8'
									d='M101.237,59.964a15.757,15.757,0,0,1,0-22.616,17.913,17.913,0,0,1,24.184,0,15.756,15.756,0,0,1,0,22.616,17.913,17.913,0,0,1-24.184,0Zm6-17.658a8.463,8.463,0,0,0-2.348,6.263,8.464,8.464,0,0,0,2.348,6.263,9.069,9.069,0,0,0,12.179,0,8.464,8.464,0,0,0,2.348-6.263,8.464,8.464,0,0,0-2.348-6.263,8.194,8.194,0,0,0-6.089-2.349A7.7,7.7,0,0,0,107.24,42.306Z'
									transform='translate(20.735 6.802)'
								/>
								<path
									id='Path_9'
									data-name='Path 9'
									d='M125.242,33.225h8l3.914,21.311h.435l4.089-21.311h9.656l4.088,21.311h.435l3.828-21.311h8.09l-6.35,30.445H150.643l-3.914-20.441h-.435L142.38,63.669H131.506Z'
									transform='translate(26.92 6.926)'
								/>
								<path
									id='Path_10'
									data-name='Path 10'
									d='M162.18,63.669V33.225h23.486v7.394h-15.4v4.61h12.352v6.524H170.27v4.523h15.4v7.394Z'
									transform='translate(34.856 6.926)'
								/>
								<path
									id='Path_11'
									data-name='Path 11'
									d='M184.383,63.669V33.225h15.223a12.693,12.693,0,0,1,8.786,3.045,9.671,9.671,0,0,1,3.393,7.568c0,3.914-2,6.959-6.09,9.133l6.264,10.612h-8.7l-5.393-9.133h-5.394v9.133h-8.09Zm8-16.527h6.959a4.472,4.472,0,0,0,2.958-.957,2.828,2.828,0,0,0,0-4.523,4.641,4.641,0,0,0-2.958-.957h-6.959Z'
									transform='translate(39.626 6.926)'
								/>
								<path
									id='Path_12'
									data-name='Path 12'
									d='M208.3,53.976h8.263a4.979,4.979,0,0,0,1.131,1.827,6.987,6.987,0,0,0,5.133,1.827,6.592,6.592,0,0,0,3.393-.7,2.16,2.16,0,0,0,1.131-1.827,1.471,1.471,0,0,0-.261-.87,3.306,3.306,0,0,0-.784-.783,6.485,6.485,0,0,0-1.044-.609,9.315,9.315,0,0,0-1.478-.522c-.61-.174-1.219-.348-1.653-.435s-1.044-.261-1.913-.435c-.784-.174-1.479-.348-1.913-.435a18.726,18.726,0,0,1-6.612-3.131,6.848,6.848,0,0,1-2.434-5.741,8.642,8.642,0,0,1,3.392-6.785c2.262-1.827,5.306-2.784,9.221-2.784,4.959,0,8.612,1.566,11.048,4.784a11.448,11.448,0,0,1,2.175,4.784h-8.263a3.794,3.794,0,0,0-.87-1.3,5.772,5.772,0,0,0-4.089-1.3,6.045,6.045,0,0,0-3.131.609,1.757,1.757,0,0,0-.957,1.566,2.177,2.177,0,0,0,1.478,1.914,24.407,24.407,0,0,0,4.263,1.392,20.687,20.687,0,0,1,2.348.609c.435.087,1.218.348,2.262.7a14.184,14.184,0,0,1,2.436.957,20.723,20.723,0,0,1,2,1.218,7.846,7.846,0,0,1,1.74,1.566,11.576,11.576,0,0,1,1.044,2.088,8.193,8.193,0,0,1,.435,2.609,8.892,8.892,0,0,1-3.392,7.046c-2.262,1.914-5.48,2.87-9.569,2.87-5.133,0-9.134-1.74-11.918-5.306A15.57,15.57,0,0,1,208.3,53.976Z'
									transform='translate(44.764 6.787)'
								/>
								<path
									id='Path_13'
									data-name='Path 13'
									d='M0,71.675H66.895V0H0ZM63.763,3.131V42.623A49.567,49.567,0,0,0,33.4,32.358,50.118,50.118,0,0,0,3.045,42.623V3.131ZM3.132,68.544V46.624A46.672,46.672,0,0,1,33.491,35.49,47.141,47.141,0,0,1,63.85,46.624v21.92Z'
									transform='translate(0.014 -0.212)'
								/>
							</g>
						</svg>
					)
				}
				url={'/'}
			/>
		</LogoStyle>
	);
});

export default Logo;
