import { useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';

//* HOO'C
import { withLanguageContext, withUIContext } from '@/context';

//* Styles
import NavigationBuildingStyle from './style';

//* Helpers
import { config } from '@/helpers';

//* Component
import Text from '../../Text';
import Icon from '../../Icon';
import CustomLink from '../../CustomLink';

const NavigationBuilding = ({ navigation, className, translate, isBurgerMenu, isBurgerMenuOpen, closeBurgerMenu, selectedLang }) => {
	//! State
	const [buildingNav, setBuildingNav] = useState(false);

	//! Refs
	const wrapperNavigationBuilding = useRef();
	const wrapperNavigation = useRef();

	//! Router
	const pathname = usePathname();

	//! Buildings control open close
	function clickNavigationBuildings(event) {
		if (!wrapperNavigation.current.contains(event.target) || !event.target.querySelector('.wrapper-item')) {
			setBuildingNav(!buildingNav);
			if (isBurgerMenuOpen && wrapperNavigation.current.contains(event.target)) {
				closeBurgerMenu();
			}
		}
	}

	const handleClickOutside = (event) => {
		if (wrapperNavigationBuilding.current && !wrapperNavigationBuilding.current.contains(event.target)) {
			setBuildingNav(false);
		}
	};

	useEffect(() => {
		if (buildingNav) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [buildingNav]);

	return (
		<NavigationBuildingStyle
			ref={wrapperNavigationBuilding}
			onClick={clickNavigationBuildings}
			className={`${className} ${isBurgerMenu ? ' is-burger-menu' : ''}`}>
			<div className={`wrapper-title ${isBurgerMenu ? '' : 'cursor-pointer'}`}>
				<Text className={'font-montserrat-medium h6 capitalize buildings-title main-blue'}>{translate('buildings')}</Text>
				<Icon
					className={`building-arrow  ${buildingNav ? 'activeIcon' : 'unactiveIcon'}`}
					name={`arrow`}
				/>
			</div>
			<div className='wrapper-navigation-building'>
				<div
					ref={wrapperNavigation}
					className={`navigation-inner-building ${buildingNav ? 'active' : 'unactive'}  ${isBurgerMenu ? 'active' : 'unactive'}`}>
					{navigation.map((item, i) => {
						return (
							<div
								key={i}
								className={`wrapper-item ${pathname.replace(selectedLang, '').replace(/^\/+|\/+$/g, '') == `buildings/${item.slug}` ? 'active-link' : ''}`}>
								<CustomLink
									url={`/buildings/${item.slug}`}
									className={'item h6'}>
									{translate('building')}&nbsp;{item.name}
								</CustomLink>
							</div>
						);
					})}
				</div>
			</div>
		</NavigationBuildingStyle>
	);
};

export default withUIContext(withLanguageContext(NavigationBuilding, ['translate', 'selectedLang']), ['closeBurgerMenu', 'isBurgerMenuOpen']);
