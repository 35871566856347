import styled from 'styled-components';

const LogoStyle = styled.div`
	--logoWidth: var(--sp37x);
	--logoHeight: var(--sp9x);

	width: var(--logoWidth);
	a {
		display: flex;
		width: 100%;
	}

	svg {
		width: var(--logoWidth);
		height: var(--logoHeight);

		path {
			fill: var(--black1000);
		}

		&.white-logo {
			path {
				fill: var(--white);
			}
		}
	}

	/* .image-cont {
		--proportion: var(--logoHeight);

		img {
			object-fit: contain;
		}
	} */
	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--logoWidth: var(--sp32x);
		--logoHeight: var(--sp8x);
	}
	/* //!1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--logoWidth: var(--sp28x);
		--logoHeight: var(--sp7x);
	}
	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--logoWidth: var(--sp21x);
		--logoHeight: var(--sp5x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--logoWidth: var(--sp20x);
		--logoHeight: var(--sp5x);
	}
	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--logoWidth: var(--sp17x);
		--logoHeight: var(--sp4x);
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--logoWidth: var(--sp17x);
		--logoHeight: var(--sp4x);
	}
	/* //! 414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--logoWidth: var(--sp11x);
		--logoHeight: var(--sp3x);
	}
`;

export default LogoStyle;
