'use client';

const ThirdParties = () => {
	return (
		<>
			{/* Service Worker */}
			<script
				dangerouslySetInnerHTML={{
					__html: `
                                var sw = {
                                    "url": "\/service-worker.js"
                                };
                                if ( 'serviceWorker' in navigator ) {
                                    window.addEventListener( 'load', function () {
                                        console.log( 'loading SW' )
                                        navigator.serviceWorker.register( sw.url )
                                            .then( function ( registration ) {
                                                console.log( 'Service worker registered' );
                                            } )
                                            .catch( function ( error ) {
                                                console.log( 'Registration failed with ' + error );
                                            } );
                                    } );
                                }
                            `,
				}}
			/>
		</>
	);
};

export default ThirdParties;
