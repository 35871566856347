import { useCallback, useEffect, useRef } from 'react';

//* Styles
import BurgerMenuStyle from './style';

//* HOO'C
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Lottie
import burger from '@/lottieFiles/burgerMenu.json';

//* Component
import Button from '../../Button';
import Container from '../../Container';
import CustomLink from '../../CustomLink';
import LottieAnimation from '../../LottieAnimation';
import NavigationBuilding from '../NavigationBuilding';
import PopupRequestCallOrReservation from '../../PopupRequestCallOrReservation';

const BurgerMenu = ({ toggleBurgerMenu, isBurgerMenuOpen, globalData, selectedLang, closeBurgerMenu, openPopup }) => {
	const menuOpenRef = useRef();
	const wrapperBurgerOpen = useRef();
	const requestBurgerRef = useRef();

	const handleWindowClick = useCallback(
		(e) => {
			if (requestBurgerRef.current?.contains(e.target) && !requestBurgerRef.current.classList?.contains('.active-link')) {
				closeBurgerMenu();
			}
			if (!menuOpenRef.current?.contains(e.target) && wrapperBurgerOpen.current?.contains(e.target)) {
				closeBurgerMenu();
			}
		},
		[requestBurgerRef]
	);

	useEffect(() => {
		window.addEventListener('click', handleWindowClick);

		return () => {
			window.removeEventListener('click', handleWindowClick);
		};
	}, []);

	return (
		<BurgerMenuStyle>
			<div className='burger-menu'>
				<div
					ref={wrapperBurgerOpen}
					className={`wrapper-menu-open ${isBurgerMenuOpen ? 'active' : ''}`}>
					<div className='inner-menu'>
						<Container>
							<div
								ref={menuOpenRef}
								// onClick={(ev) => ev.stopPropagation()}
								className={`opened-menu ${isBurgerMenuOpen ? 'active' : ''}`}>
								<NavigationBuilding
									isBurgerMenu
									navigation={globalData[selectedLang].buildings}
								/>

								{isBurgerMenuOpen ? (
									<Button
										className={'burger-button'}
										text={'requestACall'}
										colorType={'red'}
										onClick={() => {
											openPopup(<PopupRequestCallOrReservation />);
											closeBurgerMenu();
										}}
									/>
								) : null}
							</div>
						</Container>
					</div>
				</div>

				<div
					className='lottie-wrap'
					onClick={() => toggleBurgerMenu()}>
					<LottieAnimation
						autoplay={false}
						isPlay={isBurgerMenuOpen}
						isRev={!isBurgerMenuOpen}
						loop={false}
						animData={burger}
					/>
				</div>
			</div>
		</BurgerMenuStyle>
	);
};

export default withLanguageContext(withDataContext(withUIContext(BurgerMenu, ['toggleBurgerMenu', 'closeBurgerMenu', 'isBurgerMenuOpen', 'openPopup']), ['globalData']), ['selectedLang']);
