'use client';

import { memo as Memo, useEffect, useState, useRef, useCallback } from 'react';
import { usePathname } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import { withUIContext, withDataContext, withLanguageContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Style
import HeaderStyle from './style';

//* Components
import BurgerMenu from './BurgerMenu';
import Logo from '@/components/common/Logo';
import Button from '@/components/common/Button';
import LanguageSwitcher from './LanguageSwitcher';
import NavigationBuilding from './NavigationBuilding';
import CustomLink from '@/components/common/CustomLink';
import PopupRequestCallOrReservation from '@/components/common/PopupRequestCallOrReservation';

const AppHeader = Memo(({ winWidth, closeBurgerMenu, isBurgerMenuOpen, openPopup, globalData, selectedLang }) => {
	//! Router
	const pathname = usePathname();

	//! Refs
	const defaultScroll = useRef(0);
	const headerRef = useRef();

	//! States
	const [scrollDirection, setScrollDirection] = useState(true);
	const [isLanguageSwitcherActive, setLanguageSwitcherActive] = useState(false);
	const [headerHeight, setHeaderHeight] = useState();

	//! Header navigation function
	const handleNavigation = useCallback(() => {
		closeBurgerMenu(false);
		if (window.scrollY > defaultScroll.current && window.scrollY > 0) {
			setScrollDirection(false);
		} else if (window.scrollY < defaultScroll.current || window.scrollY <= 0) {
			setScrollDirection(true);
		}

		defaultScroll.current = window.scrollY;
	}, [pathname]);

	//! Header navigation
	useEffect(() => {
		window.addEventListener('scroll', handleNavigation);

		return () => {
			window.removeEventListener('scroll', handleNavigation);
		};
	}, [handleNavigation, pathname]);

	useEffect(() => {
		gsap.to(headerRef.current, { opacity: 1, ease: 'power3.easeOut', duration: 1, delay: 0.8 });
	}, []);

	return (
		<HeaderStyle
			ref={headerRef}
			$header_height={headerHeight}
			className={`header ${scrollDirection ? `visible` : ``}`}>
			<div className='inner-header-wrapper'>
				<div className='wrapper-logo-menu'>
					<Logo
						onClick={() => (isBurgerMenuOpen ? closeBurgerMenu() : null)}
						className={'header-logo'}
					/>
					<div className='navigation-wrapper'>
						<NavigationBuilding navigation={globalData[selectedLang].buildings} />
					</div>
				</div>

				<div className='button-language-wrapper'>
					<LanguageSwitcher
						setLanguageSwitcherActive={setLanguageSwitcherActive}
						isLanguageSwitcherActive={isLanguageSwitcherActive}
					/>
					<CustomLink
						external
						url={`tel:${globalData[selectedLang].phone_number}`}>
						<Button
							colorType={'white'}
							className={'header-button phone-button'}
							onClick={() => {
								isBurgerMenuOpen ? closeBurgerMenu() : '';
							}}>
							{globalData[selectedLang].phone_number}
						</Button>
					</CustomLink>

					<Button
						className={'header-button request-button'}
						text={'requestACall'}
						colorType={'red'}
						onClick={() => {
							openPopup(<PopupRequestCallOrReservation />);
						}}
					/>
					{winWidth < 1024 ? <BurgerMenu /> : null}
				</div>
			</div>
		</HeaderStyle>
	);
});

export default withLanguageContext(withDataContext(withUIContext(AppHeader, ['winWidth', 'toggleBurgerMenu', 'isBurgerMenuOpen', 'closeBurgerMenu', 'openPopup']), ['globalData']), ['selectedLang']);
