'use client';
import { memo as Memo, useEffect, useMemo, useCallback, useRef } from 'react';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Component's
import Icon from '@/components/common/Icon';

//* Style
import PopupStyle from './style';
import gsap from 'gsap/gsap-core';
import getScrollbarWidth from './scroll-bar';

const Popup = Memo(({ closePopup, popupIsOpen, popupComponent, popupProps }) => {
	const components = useMemo(() => ({}), []);

	//! Ref
	const popupRefs = useRef();

	const Child = useMemo(() => typeof popupComponent === 'string' && components[popupComponent] && components[popupComponent], [popupComponent]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown);

		if ('virtualKeyboard' in navigator) {
			navigator.virtualKeyboard.overlaysContent = true;
		}

		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, []);

	const handleKeydown = useCallback((e) => {
		if (e.key === 'Escape') {
			closePopup();
		}
	}, []);

	//! Popup no resize content document
	useEffect(() => {
		const smooth = document.querySelector('.ScrollSmoother-wrapper');
		const header = document.getElementsByTagName('header')[0];
		const scrollbarWidth = getScrollbarWidth();
		if (smooth && header) {
			if (popupIsOpen) {
				smooth.style.paddingRight = `${scrollbarWidth}px`;
				header.style.paddingRight = `${scrollbarWidth}px`;
			} else {
				smooth.style.paddingRight = '0px';
				header.style.paddingRight = '0px';
			}
		}
	}, [popupIsOpen]);

	//! Animation Popup
	useEffect(() => {
		if (popupIsOpen) {
			gsap.fromTo(
				popupRefs.current,

				{ scale: 0 },

				{
					scale: 1,
					duration: 0.6,
					ease: 'back.out',
				}
			);
		}
	}, [popupIsOpen]);

	//! Custom Anim Close Popup
	const handleAnimClosePopup = () => {
		gsap.fromTo(
			popupRefs.current,

			{ scale: 1 },

			{
				scale: 0,
				duration: 0.6,
				ease: 'back.in',
			}
		).then(() => {
			closePopup();
		});

		return;
	};

	return popupIsOpen ? (
		<PopupStyle className={`${popupProps.className || ''}`}>
			<div
				className={`popup-background`}
				onClick={handleAnimClosePopup}
			/>

			<div
				ref={popupRefs}
				className={`popup-content-container`}>
				<div className={`popup-content-wrap`}>
					<div
						className={'close-wrap'}
						onClick={handleAnimClosePopup}>
						<Icon name={`cross`} />
					</div>

					{typeof popupComponent === 'string' ? <Child /> : popupComponent}
				</div>
			</div>
		</PopupStyle>
	) : null;
});

export default withUIContext(Popup, ['closePopup', 'popupIsOpen', 'popupComponent', 'popupProps']);
