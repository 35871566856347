import styled from 'styled-components';

const LanguageSwitcherStyle = styled.div`
	.selected-lang-wrapper {
		display: flex;
		align-items: center;
		cursor: pointer;

		.language-arrow {
			margin-right: var(--sp1x);
			transition: var(--trTime);

			&.active {
				transform: rotate(180deg);
			}
		}

		.selected-lang {
			text-transform: capitalize;
		}
	}

	.languages-cont {
		position: relative;
		z-index: 1;

		.body-container {
			display: flex;
			flex-direction: column;
			position: absolute;
			top: 0;
			right: 0;
			clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
			transition: clip-path var(--trTime);

			overflow: hidden;

			.lang-item {
				margin-top: var(--sp1x);
				text-transform: capitalize;
				transition: var(--trTime);

				@media (hover: hover) {
					&:hover {
						color: var(--green1000);
					}
				}
			}

			&.active {
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
			}
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
	}
	/* //! 1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
	}
	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}
	/* //! 1280 */

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}
	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
	}
	/* //! 414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
	}
`;

export default LanguageSwitcherStyle;
