export default function getScrollbarWidth() {
	const outer = document.createElement('div');

	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll';
	outer.style.width = '100px';
	outer.style.position = 'absolute';
	document.body.appendChild(outer);

	const inner = document.createElement('div');
	inner.style.width = '100%';
	outer.appendChild(inner);

	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

	document.body.removeChild(outer);

	return scrollbarWidth;
}
