import styled from 'styled-components';

const BurgermenuStyle = styled.div`
	--radiusBurger: var(--sp3x);
	--padTB: var(--sp4x);
	--padLR: var(--sp3x);

	.inner-menu {
		width: 100%;
		height: calc(100dvh - var(--headerFixedHeight));
	}

	.burger-menu {
		.wrapper-menu-open {
			height: 0;
			overflow: hidden;
			&.active {
				height: auto;
				overflow: hidden;
			}
		}
	}

	.lottie-wrap {
		svg {
			g {
				g {
					path {
						stroke: var(--black1000) !important;
					}
				}
			}
		}
	}

	.opened-menu {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: var(--white);
		border-radius: var(--radiusBurger);
		padding: var(--padTB) var(--padLR);

		.opened-menu-link {
			font-size: var(--sp3x);
			margin-top: var(--sp2x);

			&.active-link {
				color: var(--green1000);
				pointer-events: none;
			}
		}
	}

	.wrapper-menu-open {
		position: absolute;
		width: 100%;
		left: 0;
		top: var(--headerFixedHeight);
		opacity: 0;
		transition: opacity var(--trTime) ease-out;

		&.active {
			opacity: 1;
		}
	}

	.burger-button {
		width: fit-content;
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
	}
	/* //! 1510 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
	}
	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
	}
	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
	}
	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		.burger-button {
			display: none;
		}
	}
	/* //! 414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		.burger-button {
			margin-top: var(--sp4x);
		}
	}
`;
export default BurgermenuStyle;
